import { Component, Input, OnInit } from '@angular/core';
import { AdminMenu } from '../../model/common/admin-menu-model';
import MainMenu from '../../../../assets/data/main-menu.json';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-admin-side-bar',
  templateUrl: './admin-side-bar.component.html',
  styleUrls: ['./admin-side-bar.component.scss']
})
export class AdminSideBarComponent implements OnInit {
  @Input() menuType :string =null;
  view_contact :boolean = false;
  adminMenu:any[]=[];
  constructor() { }

  ngOnInit(): void {
    if(this.menuType == 'admin'){
      this.adminMenu=MainMenu.adminSideBar;
      // hiding reminder menu
      // if(environment.type == "prod"){
      //   const filterMenu = this.adminMenu.map((mainMenu)=>{
      //     if(mainMenu.id === 3 && mainMenu.child){
      //       return { 
      //         ...mainMenu, 
      //         child: mainMenu.child.filter((childItem) => childItem.id !== 4) 
      //       };
      //     }else { 
      //       return mainMenu; 
      //     }
      //   })
      //   this.adminMenu = filterMenu;
      // }
    }else if(this.menuType == 'employee' || this.menuType == 'manager'){
      this.adminMenu=MainMenu.userSideBar;
    }else{
      this.adminMenu=MainMenu.subscriptions;
    }
  }



}
