import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function; // Declare gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BIZPAY';

 constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.urlAfterRedirects === '/user/onboard') {
        gtag('event', 'conversion_event_signup_1', {
          'event_category': 'User Onboarding',
          'event_label': 'Signup Flow',
          'value': 1
        });
      }
    });
  }
}
