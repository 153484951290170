<div class="row h-100">
    <div class="col-4 offset-4 m-auto text-center">
      <div class="p-10" *ngIf="is_locked_out">
        <h3>You have been logged out</h3>
        <h4>{{message}}</h4>
      </div>
        <div class="p-10" *ngIf="!is_locked_out">
            <h3  *ngIf="reset_pin">Enter Secure PIN</h3>
            <h3  *ngIf="!reset_pin">Verify OTP</h3>
            <h5 class="mb-3"  *ngIf="reset_pin">Enter your Bizpay security code</h5>
            <h5 class="mb-3" *ngIf="!reset_pin">Enter the OTP to reset PIN</h5>
            <p  *ngIf="!reset_pin">
              An OTP has been sent to your mobile number ending in <strong>xxxxxx{{mobile | slice:-4}}</strong> to reset your security code. This is a security measure to ensure that only authorized users can reset their code
              </p>
            <p  *ngIf="reset_pin">This code is necessary for accessing sensitive app features, including viewing the wallet screen, loading money into wallets etc.</p>
            <ng-otp-input
            #otpInput
            (onInputChange)="onOtpChange($event)"
            [config]=config
          ></ng-otp-input>
          <div class="mt-3 mb-1">
            <small *ngIf="message" [ngClass]="message.status ? 'text-success': 'text-danger'">{{message.message}}</small>
          </div>
          <div class="mt-1 me-5 float-end" *ngIf="reset_pin">
            <span class="text-danger pointer" (click)="resetPin()">Reset PIN?</span>

          </div>

          <div class="button-gap" >
            <button
              type="button"
              (click)="cancelPin()"
              class="btn btn-outline-primary w-50 mx-auto"
              *ngIf="reset_pin"
            >Cancel</button>
            <button
            type="button"
            (click)="cancelPin()"
            class="btn btn-outline-primary mx-3 "
            *ngIf="!reset_pin"
          >Cancel</button>
            <!-- <p-button label="Verify PIN" styleClass="px-4" (click)="checkPin()" *ngIf="reset_pin" [disabled]="pin?.length !=4"></p-button> -->
            <p-button label="Submit OTP" styleClass="px-4" (click)="verifyOTP()" *ngIf="!reset_pin" [disabled]="pin?.length !=4"></p-button>
          </div>
        </div>
    </div>


</div>