<!-- <form [formGroup]="exportform" (ngSubmit)="getExportData()">
  <div class="row">
    <div class="col-sm-4 col-lg-4">
      <div class="form-group">
        <label for="start_date">Start Date:</label>
        <p-calendar
          id="start_date"
          [style]="{ width: '100%' }"
          formControlName="start_date"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
    <div class="col-sm-4 col-lg-4">
      <div class="form-group">
        <label for="end_date">End Date:</label>
        <p-calendar
          id="end_date"
          [style]="{ width: '100%' }"
          formControlName="end_date"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="user_dropdown">User Dropdown:</label>
        <ng-container *ngIf="userList$ | async as userList">
          <p-dropdown
            [options]="userList | dropdownToValuePipe"
            [style]="{ width: '100%' }"
            placeholder="Select a user"
            [filter]="true"
            [formControlName]="'user_id'"
            [autoDisplayFirst]="false"
            appendTo="body"
          ></p-dropdown>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <p class="text-primary pointer">Advance option</p>
  </div>

  <div class="col-12">
    <div *ngIf="fields && fields.length">
      <div formArrayName="checkboxes_mapping" *ngFor="let items of exportform.get('checkboxes_mapping')['controls']; let i = index" >
        <div [formGroupName]="i" >
            <p-checkbox
              [formControlName]="items.value.status"
              [binary]="true"
            ></p-checkbox>
            <label class="mx-2" >{{ items.value.name }}</label>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12 text-center">
    <button class="btn btn-primary" type="submit">Export</button>
  </div>
</form> -->
<!-- CSV will be sent to email if date range diff more than or equal to 14 days -->
<!-- <p>These will download a CSV file containing data of selected date range (Maximum 30 days) and column.</p> -->

<div class="row">
  <div class="col-sm-12 col-md-4">
    <div class="form-group">
      <label for="start_date">From Date:</label>
      <p-calendar
        id="start_date"
        [style]="{ width: '100%' }"
        [(ngModel)]="start_date"
        dateFormat="dd-mm-yy"
        [showIcon]="true"
        [maxDate]="end_date"
      ></p-calendar>
      <div *ngIf="startDateError">
        <small class="text-danger">Invalid date</small>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-4">
    <div class="form-group">
      <label for="end_date">To Date:</label>
      <p-calendar
        id="end_date"
        [style]="{ width: '100%' }"
        [(ngModel)]="end_date"
        dateFormat="dd-mm-yy"
        [showIcon]="true"
        [minDate]="start_date"
      ></p-calendar>
      <div *ngIf="endDateError">
        <small class="text-danger">Invalid date</small>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-12" *ngIf="toggled">
    <div class="form-group">
      <label for="file_name">File Name:</label>
      <div class="p-inputgroup">
        <input type="text" [(ngModel)]="file_name"  pInputText [pKeyFilter]="'alpha'"     pAutoFocus
        [autofocus]="true"  placeholder="File Name" />
        <span class="p-inputgroup-addon">.csv</span>
    </div>
    </div>
  </div>
  <div class="col-12 " *ngIf="toggled">
    <h6>Include Columns</h6>
    <p>Select which columns to include in the report.</p>
    <form [formGroup]="form" class="row">
      <div class="col-sm-3 col-md-4" *ngFor="let item of fields;">
        <div >
          <input type="checkbox" [formControlName]="item.value" id="{{item.value}}">
          <label for="{{item.value}}" class="mx-2">{{item.name}}</label>
        </div>

      </div>

    </form>
  </div>
  <div class="col-12 text-center my-3">
    <!-- <button class="btn btn-primary" type="button" (click)="resetFilter()">Reset Fitler</button> -->
    <button class="btn btn-primary" type="button" (click)="onSubmit()">Export Data</button>

  </div>
</div>

