export const Constant = {
  AuthMode: {
    Login: 'login',
    SignUp: 'signup',
  },
  RoleName: {
    Admin: 'admin',
    Manager: 'manager',
    Finance: 'finance',
    User: 'employee',
  },
  DashboardRoute: {
    OnBoard: '/user/onboard',
    Admin: '/dashboard/admin-dashboard',
    Manager: '/dashboard/team',
    Finance: '/dashboard/finance-dashboard',
    User: '/dashboard/user-dashboard',
  },
  SetUpRoute: '/user/onboard',
  LocalStorageKey: {
    Token: 'apitoken',
    User: 'connectedUser',
    Url: 'navigateurl',
    CurrentRole: 'currentRole',
    ApplicationStage: 'applicationStage',
    CurrencyOptions: 'currencyOptions',
    MobileView: 'mobileView'
  },

  YesNoValue: [
    { name: 'YES', id: true },
    { name: 'NO', id: false },
  ],
  ToasterHeader: {
    Success: 'Success!',
    Warning: 'Warning!',
    Error: 'Error!',
  },
  ToasterBody: {
    Payment: 'Payment done successfully!',
    Success: 'Record saved successfully!',
    Error: 'Something went wrong!',
    Invalid: 'Please fill all required field',
    Deleted: 'Record deleted successfully',
    MailSent: 'Mail sent successfully',
  },
  RightBar: {
    Profile: 'Profile',
    Setting: 'Setting',
    Notification: 'Notification',
  },
  KYCStatus: {
    Pending: 'P',
    Initiated: 'I',
    Completed: 'C',
  },
  ApplicationStage: {
    User: 'Setup Users',
    Policy: 'Setup Policies',
    Expense: 'Create Expense Bucket',
  },
  NonTokenApi: {
    Login: 'login',
    ForgotPassword: 'forgot_password',
  },
  WorkFlow: {
    Pending: 'P',
    Approved: 'A',
    Verified: 'V',
    Rejected: 'R',
  },
  BankTransfer: {
    AddBeneficiary: 'Add Beneficiary',
    SelectBeneficiary: 'Select Beneficiary',
    ManageBeneficiary: 'Manage Beneficiary',
    QuickTransfer: 'Quick Transfer',
  },
  PDFTemplate: {
    CompanySpecific:
      '<!DOCTYPE html><html><head> <title>@Title@</title></head><body> <header class="container-fluid bg-dark text-light py-3"> <div class="container"> <div class="row"> <div class="col-12"> <h1>@CompanyName@</h1><h5>@ContentName@</h5> </div> </div> </div> </header> <main class="container mt-4"> @BODY@</main></body></html>',
  },
  PDFConstant:{
    Title:"@Title@",
    CompanyName:"@CompanyName@",
    ContentName:"@ContentName@",
    Body:"@BODY@"
  },
  notificationType: [
    { id:1, name: 'Reports Not Submitted' },  //Unsubmitted Expenses
    { id:2, name: 'UnReported Expenses'},   //UnReported Expenses
    { id:3, name: 'Reports Submitted for Approval'},     //Reports Awaiting Approval
    { id:4, name: 'Reports Awaiting Verification'},      //Reports Pending Verification
    { id:5, name: 'Money Requests Submitted for Approval'},    //Money Requests Awaiting Approval
    { id:6, name: 'Money Requests Awaiting Verification'},    //Money Requests Awaiting Verification
    // { id:7, name: 'Balance related eg: Pool balance less than a certain amt'},
  ]
};
