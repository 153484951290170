<div class="row my-3" *ngIf="data">
  <div class="col-12">
    
    {{ data.name }}
    <strong>({{ data.amount | currency
      : appdataService.currencyOption.code
      : "symbol"
      : "1.2-2"
      : appdataService.currencyOption.locale }})</strong>
    <!-- </div>
    <div class="col-9"> -->
    <div class="progress-bar">
      <div
        class="progress"
        [ngStyle]="{
          width: data.percent + '%',
          'background-color': data.color
        }"
      ></div>
    </div>
  </div>
</div>
