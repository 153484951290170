import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { Constant } from '../../common/constant';
import { LoggedUser, UserRole } from '../../model/users/logged-user.modal';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { UserService } from 'app/modules/user/service/user.service';

@Component({
  selector: 'app-setting-menu',
  templateUrl: './setting-menu.component.html',
  styleUrls: ['./setting-menu.component.scss'],
})
export class SettingMenuComponent implements OnInit {
  //#region
  @Output() closeSideBarEvent = new EventEmitter<boolean>();
  @Output() roleChanged = new EventEmitter<string>();

  loggedInUser: LoggedUser;
  currentRole: string = '';
  on_board_mode: boolean = false;
  isprimaryaccount: boolean = false;
  isadmin: boolean = false;
  enableOnBoard: string = '';
  hideOnBoard: boolean = false;
  toggleFlag: boolean = false;
  //#endregion
  //#region Constructor
  constructor(
    private appData: AppDataService,
    private route: Router,
    private authService: AuthenticationService,
    private userService: UserService
  ) {}
  //#endregion
  //#region
  ngOnInit(): void {
    this.loggedInUser = this.appData.connectedUser;
    this.currentRole = this.appData.currentUserRole;
    this.on_board_mode =
      JSON.parse(localStorage.getItem('allow-on-boarding-process')) !== null;
    this.isprimaryaccount = this.loggedInUser.primary_account;
    this.isadmin = this.currentRole.toLowerCase() === 'admin';
    this.enableOnBoard = 'Onboarding';
    if (this.isprimaryaccount) {
      this.toggleFlag = !this.loggedInUser.status;
    }
  }

  setRole(role?: UserRole) {
    if (this.on_board_mode) {
      return;
    }
    if (role) {
      this.appData.currentUserRole = role.role_name;
      this.currentRole = role.role_name;
      this.appData.changeRole(role);
      this.closeSideBarEvent.emit(true);
      if (
        (this.currentRole.toLocaleLowerCase() ==
          Constant.RoleName.Admin.toLocaleLowerCase() ||
          this.currentRole.toLocaleLowerCase() ==
            Constant.RoleName.Finance.toLocaleLowerCase() ||
          this.currentRole.toLocaleLowerCase() ==
            Constant.RoleName.Manager.toLocaleLowerCase() ||
          this.currentRole.toLocaleLowerCase() ==
            Constant.RoleName.User.toLocaleLowerCase()) &&
        this.loggedInUser.primary_account &&
        !this.loggedInUser.status
      ) {
        this.currentRole = this.isprimaryaccount ? 'Onboarding' : 'Onboarding';
        this.route.navigateByUrl(Constant.DashboardRoute.OnBoard);
      }
      if (this.currentRole.toLocaleLowerCase() == Constant.RoleName.Admin) {
        this.hideOnBoard = false;
        this.route.navigateByUrl(Constant.DashboardRoute.Admin);
      } else if (
        this.currentRole.toLocaleLowerCase() == Constant.RoleName.Finance
      ) {
        this.hideOnBoard = true;
        this.route.navigateByUrl(Constant.DashboardRoute.Finance);
      } else if (
        this.currentRole.toLocaleLowerCase() == Constant.RoleName.User
      ) {
        this.hideOnBoard = true;
        this.route.navigateByUrl(Constant.DashboardRoute.User);
      } else if (
        this.currentRole.toLocaleLowerCase() == Constant.RoleName.Manager
      ) {
        this.hideOnBoard = true;
        this.route.navigateByUrl(Constant.DashboardRoute.Manager);
      }
      this.roleChanged.emit(this.currentRole.toLowerCase());
    } else {
      this.on_board_mode;
      this.currentRole = '';
    }
  }

  logout() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    localStorage.clear();
    localStorage.setItem('logout', Date.now().toString()); // 🔹 Broadcast logout event

    this.authService.logout().subscribe(
      (x) => {
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/'
            );
        });
        this.route.navigate(['/login']);
      },
      (error) => {
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/'
            );
        });
        this.route.navigate(['/login']);
      }
    );
  }

  toggleOnBoardStatus() {
    let status = {
      on_boarded_status: !this.loggedInUser.status,
    };
    this.userService
      .toggleOnBoardingProcess(this.loggedInUser.organization.id, status)
      .subscribe((x) => {
        this.loggedInUser.status = !this.loggedInUser.status;
        if (!this.loggedInUser.status) {
          this.toggleFlag = true;
          this.appData.changeRole({ role_id: 1, role_name: 'Admin' });
          localStorage.setItem('allow-on-boarding-process', 'true');
          this.route.navigateByUrl('/user/onboard');
        } else {
          localStorage.removeItem('allow-on-boarding-process');
          this.route.navigateByUrl('/login');
        }
        this.appData.connectedUser = this.loggedInUser;
        this.closeSideBarEvent.emit(true);
      });
  }
  //#endregion
}
