//We Can Authorise Other routes Also
export const RouteConstantAdmin ={
    route:{
        AdminDashboard: '/dashboard/admin-dashboard',
        Wallets:'/transcation/card',
        Settelements:'/settlement',
        Reports:'/report/report',
        Expenses:'/expense/expense',
        Teams:'/dashboard/teams',
        MoneyRequests:'/transcation/money-request-dashboard',
        Approvals:'/expense/approval',
        Settings:'/user/setting',
        OrganisationSettings:'/administrator/',
        OrganizationDetails:'/administrator/organization',
        Integrations:'/administrator/integration',
        Expense:'/expense/expense-type',
        ExpenseBucket:'/expense/expense-bucket',
        UserSettings:'/user',
        Users:'user/user-list',
        Roles:'/administrator/role',
        Policies:'/administrator/policy',
        Onboarding:'/user/onboard',
        ControlPanel:'/admin-panel',
        OrganizationList:'/admin-panel/organization-list',
        ExpenseForm:'/expense/expense-form',
        ReportDashboard:'/report/report-dashboard/'
        //#Add More routes if Required
      }
}
export const RouteConstantFinance={
    route:{
        FinanceDashboard:'/dashboard/finance-dashboard',
        Wallets:'/transaction/card',
        Reports:'/report/reports',
        Expenses:'/expense/expense',
        Teams:'/dashboard/team',
        MoneyRequests:'/transcation/money-request-dashboard',
        Approvals:'/user/approvals',
        ExpenseForm:'/expense/expense-form',
        ReportDashboard:'/report/report-dashboard/'
        //#Add more routes if Required
    }
}
export const RouteConstantManager={
    route:{
    Teams:'/dashboard/team',
    Expense:'/expense/expense-bucket-dashboard',

    //#Add more routes if Required
    }
}
export const RouteConstantUsers={
    route:{
    UserDashboard:'/dashboard/user-dashboard',
    Reports:'/report/report',
    MyWallets:'/transaction/wallet',
    MoneyRequests:'/transcation/money-request-dashboard',
    Approvals:'/expense/approvals',
    Settings:'/user/setting',
    Expenses:'/expense/expense',
    ExpenseForm:'/expense/expense-form',
    ReportDashboard:'/report/report-dashboard/'

    //#Add more routes if Required
    }
}


//#region Administrator
export const AdministatorPath={
  Organization:'organization',
  SetOrganization:'set-organization',
  PolicyList:'policy',
  PolicyForm:'policy/policy-form',
  TeamList:'team',
  TeamForm:'team/team-form',
  Role:'role',
  Integration:'integration',
  Zapier:'zapier',
  ManageApi:'manage-api',
  SystemCharges:'system-charges',
  Subscription:'subscription',
  ManageSubscription:'manage-subscription',
  UpgradeSubscription:'upgrade-subscription',
  OrganizationPurchase:'organization-purchase',
  Support:'support',
  FAQ:'faq',
  VideoTutorial:'video-tutorial',
  TaxType:'tax-type',
  Administrator:'administrator'
}

export const AuthenticationPath={
    Login:'login',
    SignUp:'sign-up',
    SetUp:'set-up',
    ForgotPassword:'forgot-password',
    UnAuth:'unauth',
    PrivacyPolicyBizpay:'privacy-policy-bizpay',
    RefundPolicy:'refund-policy',
    TermsAndCondition:'terms-and-condition',
    ResetPassword:'reset-password',
    Payment:'payment',
    SignUpBasic:'sign-up-basic',
    ViewProforma:'view/proforma',
    UserVerification:'user-verification',
    VerificationHandler:'verification-handler',
    OpenApp:'open-app',
    LoadApp:'load-app',
    Verification:'verification',
    ApiReference:'api-reference',
    StaticSignUp:'static-signup',
    DeleteAccount:'delete-account',
    Expired:'expired',
    KycInitialize:'kyc-initialize'
}

export const DashboardPath={
    UserDashboard:'user-dashboard',
    AdminDashboard:'admin-dashboard',
    FinanceDashboard:'finance-dashboard',
    DashboardTeam: 'team',
    Redirect:'redirect',
    Dashboard:'dashboard'
}

export const ExpensePath={
    ExpenseBucket:'expense-bucket',
    ExpenseType:'expense-type',
    Expense:'expense',
    ExpenseList:'expense-list',
    ExpenseForm:'expense-form',
    Report:'report',
    ReportDashboard:'report-dashboard',
    Approval:'approval',
    ExpenseBucketDashboard:'expense-bucket-dashboard'
}

export const SettlementPath={
    Settlement:'settlement'
}

export const TransactionPath={
    Wallet:'wallet',
    MoneyRequestDashboard:'money-request-dashboard',
    BankTransfer:'bank-transfer',
    MoneyRequest:'money-request',
    Card:'card',
    BulkTransfer:'bulk-transfer',
    BulkTransferForm:'bulk-transfer-form',
    Transaction:'transcation'
}

export const UserPath={
    Onboard:'onboard',
    UserSetUp:'set-up',
    UserList:'user-list',
    UserForm:'user-list/user-form',
    UserImport:'user-import',
    Setting:'setting',
    Kyc:'kyc',
    ChangePassword:'change-password',
    AssignWallet:'assign-wallet',
    UserVerify: '-user-verification',
    ViewDisputes:'view-disputes'
}

export const ControlPanelPath={
    Home:'home',
    AddRequest:'add-request',
    EditRequest:'edit-request',
    Subscriptions:'subscriptions',
    User:'user',
    OrganizationStatistics:'organization-statistics',
    OrganizationListTrial:'organization-list-trial',
    UserSubscription:'subscription',
    SubscriptionType:'subscription-type',
    SubscriptionTypeForm:'subscription-type-form',
    OrganizationList:'organization-list',
    Proposal:'proposal',
    ProposalView:'proposal-view',
    ProposalPreview:'proposal-preview',
    CreateProposal:'create-proposal',
    Invoice:'invoice',
    UserBulkImport:'user-bulk-import',
    AssignWalletUser:'assign-wallet-user',
    OrganizationSystemCharges:'organization-system-charges',
    UserFaq:'faq',
    FaqForm:'faq-form',
    FaqEdit:'faq-edit',
    UserSearch:'user-search',
    BankNotification:'bank-notification',
    BizpayStats:'bizpay-stats',
    ChannelwiseTransactionSummary:'channelwise-transaction-summary',
    ControlPanel:'admin-panel'
}

export const SettingsPath={
    Settings:'settings',
    Reminders:'reminders',
    CreateReminder:'reminders/create-reminders',
    ExpenseBucketCategory:'expense-bucket-category',
}



//#endregion
