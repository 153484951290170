import { Dropdown } from "../common/dropdown.model";

export class User{
  id:number;
  first_name:string;
  last_name:string;
  employee_id:string;
  email:string;
  country_code:string;
  mobile:string;
  policy_id:string;
  team_id:string;
  role_ids:[];
  approvers:any[];
  verifiers:any[];
  mileage_rate:string;
  salary_rate:string;
  card_type_id:number;
  opt_for_physical_card:boolean;
  limit:LimitValue[];
  department_id:number
  address: string;
  delivery_address: string;
  can_approve:boolean;
  can_verify:boolean;
  roles:any[];
  aadhar_card_no:string;
  pan_card_no:string;
  father_name: string;
  gender: string;
  date_of_birth: string;
  kyc_status: any;
  middle_name:string;
  state_id:number;
  country_id:number;
  title_id:any;
  pin_code:string;
  city:string;
  additional_info:any;
  kyc_link: any;
  constructor(){
    this.id=null;
    this.first_name="";
    this.last_name="";
    this.email="";
    this.employee_id="";
    this.mobile="";
    this.country_code=null;
    this.approvers=null;
    this.verifiers=null;
    this.team_id=null;
    this.policy_id=null;
    this.role_ids=null;
    this.card_type_id=null;
    this.opt_for_physical_card=null;
    this.mileage_rate="";
    this.salary_rate="";
    this.department_id=null
    this.address="",
    this.father_name="";
    this.gender="";
    this.date_of_birth="";
    this.middle_name="";
    this.state_id=null;
    this.country_id=null;
    this.title_id=null;
    this.pin_code=null;
    this.city=null;
    this.kyc_status="";
    this.kyc_link="";
  }
}


export class LimitValue{
  id:number
  policyCategoryId: number
  value:number

  constructor() {
    this.id = null
    this.policyCategoryId = null
    this.value = null
  }

}
